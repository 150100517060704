import Moment from '@/utils/moment';

export const orderInfoFileds = [
  {
    prop: 'goodsCode',
    label: '货品编码:',
  },
  {
    prop: 'shipperName',
    label: '货主:',
  },
  {
    prop: 'goodsName',
    label: '货品名称:',
  },
  {
    prop: 'shipperGoodsCode',
    label: '货主货品编码:',
  },
  {
    label: '在库库存:',
    formatter: (row) => `${row.totalBigUnitNum}${row.bigUnit}${row.totalSmallUnitNum}${row.smallUnit}`,
  },
  {
    prop: 'lockedAmount',
    label: '预占量:',
  },
];

export const tableColumns = [
  {
    label: '订单号',
    prop: 'orderNo',
  },
  {
    label: '单据类型',
    prop: 'type',
  },
  {
    label: '单据状态',
    prop: 'orderStatus',
  },
  {
    label: '预约量',
    prop: 'bookingAmountStr',
  },
  {
    label: '指定批次',
    prop: 'batchNo',
  },
  {
    label: '预占时间',
    prop: 'lockedTime',
    formatter: (row) => Moment.format(row.lockedTime),
  },
];
