<template>
  <el-row>
    <el-col :span="11">
      <el-input
        v-model="rengeDataProp.smallValue"
        clearable
        size="mini"
        type="number"
        @input="rengeDataChange"
      />
    </el-col>
    <el-col :span="2" style="text-align: center;">
      -
    </el-col>
    <el-col :span="11">
      <el-input
        v-model="rengeDataProp.largeValue"
        size="mini"
        clearable
        type="number"
        @input="rengeDataChange"
      />
    </el-col>
  </el-row>
</template>

<script>

export default {
  props: {
    rangeData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:rangeData'],
  computed: {
    rengeDataProp() {
      return { ...this.rangeData };
    },
  },
  methods: {
    rengeDataChange() {
      this.$emit('update:rangeData', {
        ...this.rengeDataProp,
      });
    },
  },
};
</script>
