import { FormType } from '@/constant/form';
import unitNumber from '@/utils/unitNumber';

function formatGoodeLevel(row) {
  return `${row.goodsLevel1}/${row.goodsLevel2}/${row.goodsLevel3}`;
}
export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  minWidth: 180,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 180,
}, {
  label: '货品条码',
  prop: 'internationalCode',
  minWidth: 180,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 120,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 100,
}, {
  label: '温层',
  prop: 'goodsTemperatureLayerName',
  minWidth: 80,
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  minWidth: 120,
}, {
  label: '货品分类',
  prop: 'goodsLevel',
  minWidth: 120,
  formatter: (row) => formatGoodeLevel(row),
}, {
  label: '换算率',
  prop: 'conversionRate',
  minWidth: 80,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  minWidth: 80,
}, {
  label: '在库库存(默认单位)',
  prop: 'defaultUnitTotalNum',
  minWidth: 160,
}, {
  label: '在库库存(整单位)',
  prop: 'totalBigUnitNum',
  minWidth: 140,
  formatter: (row) => row.totalBigUnitNum + row.bigUnit,
}, {
  label: '在库库存(零单位)',
  prop: 'totalSmallUnitNum',
  minWidth: 140,
  formatter: (row) => row.totalSmallUnitNum + row.smallUnit,
}, {
  label: '可用量',
  prop: 'availableNum',
  minWidth: 160,
  formatter: (row) => (row.availableNum
    ? unitNumber.calculate({ ...row, smallUnitNumber: row.availableNum }).txt : row.availableNum),
}, {
  label: '预占量',
  prop: 'lockedNum',
  minWidth: 160,
  formatter: (row) => (row.lockedNum
    ? unitNumber.calculate({ ...row, smallUnitNumber: row.lockedNum }).txt : row.lockedNum),
}, {
  label: '冻结量',
  prop: 'frozenNum',
  minWidth: 160,
  formatter: (row) => (row.frozenNum
    ? unitNumber.calculate({ ...row, smallUnitNumber: row.frozenNum }).txt : row.frozenNum),
}, {
  label: '辅助单位数量',
  prop: 'auxiliaryAmount',
  minWidth: 160,
  formatter: (row) => (row.auxiliaryAmount ? row.auxiliaryAmount + row.auxiliaryUnit : ''),
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  minWidth: 80,
}];
export const serchFields = (GOODS_QUALITY, zoneTemperature) => [{
  label: '货主',
  prop: 'shipperId',
}, {
  label: '货品编码',
  prop: 'goodsCode',
  component: FormType.INPUT,
  componentAttrs: {
    placeholder: '商品编码/条码',
  },
}, {
  label: '货品名称',
  prop: 'goodsName',
  component: FormType.INPUT,
  componentAttrs: {
    placeholder: '商品名称模糊查询',
  },
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  component: FormType.SELECT,
  options: GOODS_QUALITY,
}, {
  label: '温层',
  prop: 'goodsTemperatureLayer',
  component: FormType.SELECT,
  options: zoneTemperature,
}, {
  label: '商品分类',
  prop: 'goodsLevel',
}, {
  label: '在库库存',
  prop: 'totalAmount',
},
{
  label: '预占量',
  prop: 'lockedAmount',
}, {
  label: '冻结量',
  prop: 'frozenAmount',
},
];
