<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields(GOODS_QUALITY,zoneTemperature)"
      @submit="handleQuery"
      @reset="handleQuery"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="searchQuery.shipperId"
        />
      </template>
      <template #goodsLevel>
        <el-cascader
          v-model="searchQuery.goodsLevel"
          :options="cascader"
          clearable
        />
      </template>
      <template #totalAmount>
        <own-range-input v-model:rangeData="searchQuery.totalAmount" />
      </template>
      <template #lockedAmount>
        <own-range-input v-model:rangeData="searchQuery.lockedAmount" />
      </template>
      <template #frozenAmount>
        <own-range-input v-model:rangeData="searchQuery.frozenAmount" />
      </template>
      <template #reservedAmount>
        <own-range-input v-model:rangeData="searchQuery.reservedAmount" />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      show-summary
      :summary-method="handleGetSummaries"
      :show-table-setting="true"
      @update:pagination="handlePaginationChange"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '在库管理/库存管理/货品库存查询/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleRecordsClick(row)">
          预占记录
        </nh-button>
      </template>
    </pltable>
  </pl-block>
  <recordsDialog ref="records" />
</template>
<script>
import loadingMixin from '@thales/loading';
import tableSummary from '@/utils/tableSummary';

import ownRangeInput from '../../components/ownRangeInput.vue';
import recordsDialog from './subPages/recordsDialog.vue';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import {
  shipperGoodsStockPage, shipperGoodsStockExport, getGoodsLevelTree,
} from './api';
import summaryConfig from './config';

export default {
  name: 'GoodsInventoryQuery',
  components: {
    recordsDialog,
    ownRangeInput,
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      searchQuery: {
        shipperId: null,
      },
      loading: {
        getTableData: false,
      },
      cascader: [],
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
  },
  created() {
    this.getCascader();
    this.getTableData();
  },
  methods: {
    async getCascader() {
      const resp = await getGoodsLevelTree({});
      this.cascader = await this.readNodes(resp);
    },
    readNodes(nodes = []) {
      const arr = [];
      nodes.forEach((item, index) => {
        arr.push({ label: item.name, value: item.name });
        if (item.children && item.children.length > 0) {
          arr[index].children = this.readNodes(item.children);
        }
      });
      return arr;
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.tableData = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await shipperGoodsStockPage(pagination, params);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    buildParams() {
      const {
        goodsLevel, totalAmount, lockedAmount, frozenAmount, reservedAmount,
      } = this.searchQuery;
      let goodsLevel1 = null;
      let goodsLevel2 = null;
      let goodsLevel3 = null;
      let totalAmountStart = null;
      let totalAmountEnd = null;
      let lockedAmountStart = null;
      let lockedAmountEnd = null;
      let frozenAmountStart = null;
      let frozenAmountEnd = null;
      let reservedAmountStart = null;
      let reservedAmountEnd = null;
      if (Object.prototype.toString.call(totalAmount) === '[object Object]') {
        totalAmountStart = Number(totalAmount.smallValue);
        totalAmountEnd = Number(totalAmount.largeValue);
      }
      if (Object.prototype.toString.call(lockedAmount) === '[object Object]') {
        lockedAmountStart = Number(lockedAmount.smallValue);
        lockedAmountEnd = Number(lockedAmount.largeValue);
      }
      if (Object.prototype.toString.call(frozenAmount) === '[object Object]') {
        frozenAmountStart = Number(frozenAmount.smallValue);
        frozenAmountEnd = Number(frozenAmount.largeValue);
      }
      if (Object.prototype.toString.call(reservedAmount) === '[object Object]') {
        reservedAmountStart = Number(reservedAmount.smallValue);
        reservedAmountEnd = Number(reservedAmount.largeValue);
      }
      if (Array.isArray(goodsLevel)) {
        [goodsLevel1 = null, goodsLevel2 = null, goodsLevel3 = null] = goodsLevel;
      }
      return {
        ...this.searchQuery,
        goodsLevel1,
        goodsLevel2,
        goodsLevel3,
        totalAmountStart,
        totalAmountEnd,
        lockedAmountStart,
        lockedAmountEnd,
        frozenAmountStart,
        frozenAmountEnd,
        reservedAmountStart,
        reservedAmountEnd,
      };
    },
    handlePaginationChange() {
      this.getTableData();
    },
    handleGetSummaries({ columns, data }) {
      const config = summaryConfig;
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = tableSummary({ column, data, config });
      });
      return sums;
    },
    async handleExport() {
      const data = this.buildParams();
      shipperGoodsStockExport(data);
    },
    handleRecordsClick(row) {
      this.$refs.records.open(row);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-cascader) {
  width: 100%;
}
</style>
