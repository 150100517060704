import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:货主货品库存分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15009
 */
export function shipperGoodsStockPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/shipper_goods_stock/page', data, {
    params,
  });
}

/**
 * @description:货主货品库存导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15011
 */
export function shipperGoodsStockExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/shipper_goods_stock/export', data, { fileName: '货主货品库存', extension: 'xlsx' });
}

/**
 * @description: 货品分类
 * @api文档：https://yapi.ops.yunlizhi.cn/project/271/interface/api/9292
 */
export function getGoodsLevelTree(data) {
  return wmsPlusHttp.post('/goods_level/tree', data);
}

/**
 * @description: 货主货品库存锁定记录查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17529
 */
export function searchShipperGoodsStockLocked(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/shipper_goods_stock/shipper_goods_stock_locked_data/query', data, {
    params,
  });
}
